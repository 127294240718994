import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import "./Footer.css"
import { AiFillFacebook, AiFillTwitterSquare, AiOutlineInstagram } from "react-icons/ai"
import NoteIcon from '../img/icon_note_white.png'


export const Footer = () => {
    return(
        <footer>
            <div className="footer">
                <div className='footer-left'>
                    <p className='company-name'>国際共創会Diligent</p>
                    <div className='icons'>
                        {/* <a href='' target='blank'><AiFillFacebook className='icon' style={{color: 'white'}}/></a> */}
                        <a href="https://note.com/utdiligent/" target='blank'><img src={NoteIcon} style={{width: '25px', margin: '0 5px 3px'}}></img></a>
                        <a href='https://twitter.com/UTokyoDiligent' target='blank'><AiFillTwitterSquare className='icon' style={{color: 'white'}}/></a>
                        <a href='https://www.instagram.com/utokyodiligent' target='blank'><AiOutlineInstagram className='icon' style={{color: 'white'}}/></a>
                    </div>
                    <p className='address'>東京都文京区本郷</p>
                    <p className='tel'>080-4584-6745</p>
                </div>
            </div>
        </footer>
    )
}