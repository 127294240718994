import React, {useState, useEffect} from 'react';
import '../css/Home.css';
import Button from '@mui/material/Button';
import {NavLink, Link} from 'react-router-dom';

export const Home = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;

    const [isEntered, setIsEntered] = useState((sessionStorage.getItem('isEntered') == "null" || sessionStorage.getItem('isEntered') == undefined) ? false : true)

    const interval = 6000;

    useEffect(() => {
        let index = 0
        const intervalId = setInterval(() => {        
            index++
        }, interval);
    
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="home">
            <h1>国際共創会Diligentは、<br></br>HPAIR Asia Conference 2025 の<br></br>東京誘致を目指しています。</h1> 
            <ul>
                <li><Link to="/about">What's HPAIR?</Link></li>
                <li><Link to="/application">Join Us</Link></li>
                <li><Link to="/contact">For Sponsors</Link></li>
            </ul>  
        </div>
    )
}