import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { init, send } from 'emailjs-com';
import { Button } from "@mui/material";
import '../css/Contact.css';

export const Contact = () => {
    const [name, setName] = useState('');
    const [kana, setKana] = useState('');
    const [mail, setMail] = useState('');
    const [content, setContent] = useState('');
    const [message, setMessage] = useState('');

    const publicKEY = 'V7QZ1KtwIz8z5-xX_';
    const serviceID = 'DILIGENT';
    const templateID = 'template_zg8eh4t'

    const [hasSent, setHasSent] = useState('');
    const [isError, setIsError] = useState('');

    const disableSend = name === '' || kana === '' || mail === '' || message === '' || content=== '';
    const isInvalid = name.length > 30 || kana.length > 30 || mail.length > 30 || content.length > 1000 || message.length > 1000

    const sendMail = () => {
        if (
            publicKEY !== undefined &&
            serviceID !== undefined &&
            templateID !== undefined &&
            !disableSend
        ) {
            init(publicKEY);
        
            const template_param = {
                name: name,
                kana: kana,
                email: mail,
                content: content,
                message: message,
            };

            emailjs.send(serviceID, templateID, template_param, publicKEY).then(() => {                              
                setIsError(false);
                setHasSent(true);
                setName('');
                setKana('');
                setMail('');
                setContent('');
                setMessage('');
              });
        }          
    }

    const handleClick = (e) => {
        if(disableSend){
            setIsError("必須項目を全て入力してください。");
        }
        if(isInvalid){
            setIsError("入力内容が長すぎます。");
        }
        e.preventDefault();
        sendMail();
    };

    return (
        <div className='contact'>
            <h1>For Sponsors</h1>
            <p>国際共創会Diligentは、会場の貸出やサービスの提供、資金援助を通じて国際会議の誘致をバックアップしてくださるスポンサー企業様を募集しています。</p>
            <form>
                <div className='flex-box'>
                    <div className='name'>
                        <div>
                            <label htmlFor="nameForm">お名前 <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="nameForm" className="formInput" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>
                    <div className='kana'>
                        <div>
                            <label htmlFor="kanaForm">フリガナ <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="kanaForm" className="formInput" value={kana} onChange={(e) => setKana(e.target.value)} required />
                    </div>
                </div>
                <div>
                    <label htmlFor="mailForm">メールアドレス <span style={{color: "red"}}>*</span></label>
                </div>
                <input type="email" id="mailForm" className="formInput" value={mail} onChange={(e) => setMail(e.target.value)} required/>
                <div>
                    <label htmlFor="contentForm">ご協力いただける内容 <span style={{color: "red"}}>*</span></label>
                </div>
                <textarea type="text" id="contentForm" className="formInput formInputContent" value={content} onChange={(e) => setContent(e.target.value)} />
                
                <div>
                    <label htmlFor="mailContentForm">その他、ご質問等があればご記入ください。 </label>
                </div>
                <textarea type="text" id="mailContentForm" className="formInput formInputMessage" value={message} onChange={(e) => setMessage(e.target.value)} />
                {
                    hasSent ? (
                        <p className='error'><b>お問い合わせが送信されました。<br />入力いただいたメールアドレスに確認メールが届いたことをご確認ください。</b></p>
                    ) : undefined
                }
                {
                    isError ? (
                        <p className='error'><span style={{color: "red"}}><b>必須項目を全て入力してください。</b></span></p>
                    ) : undefined
                }
                <div className="btns">
                    <div>
                        {hasSent ? (
                            <button className="hasSent">
                                送信完了✓
                            </button>
                        ) : (
                            <Button variant="contained" onClick={handleClick} className='unSent'>
                                送信
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
}