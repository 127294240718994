import React from 'react';
import '../css/About.css';

export const About = () => {

    return (
        <div className='about'>
            <h1>What's HPAIR?</h1>
            <p>Harvard Project for Asia and International Relations (HPAIR)は、ハーバード大学による国際会議で、1991年以来、アジア太平洋における政治・経済・社会問題について議論する場を世界中の学生に提供しています。ホスト国は政財界、文化を代表する人物の基調講演に加え、斯界を代表する教授陣・実務家を招き、大学を問わず世界中から集まる約500名の学部生・大学院生に対して4日間の集中的な教育の場を提供し、併せて、参加する学生の国際的な人的ネットワークの形成につなげております。</p>
            <p>日本はこれまでに2005年(東京大学), 2009年(早稲田大学), 2014年(慶應大学)での誘致実績があり、私たち国際共創会Diligentは来る2024年に再びHPAIRを東京に誘致することを目的として活動しています。</p>
            <p>前回大会(香港, 2022年)には日本からも数名の学生が参加し、アジアの学生を中心とともに学び、親睦を深めました。</p>
        </div>
    )
}