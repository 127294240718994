import React from 'react';
import '../css/Team.css';
import Table from '../Table';

export const Team = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;

    const teams = [
        {   
            dept: "①総務部",
            description: "国際共創会Diligentのスムーズな運営と組織管理を担います。スケジュール調整、チーム内の活発なコミュニケーションを促すことが主な業務です。"
        },
        {   
            dept: "②外交部",
            description: "国内外を問わず、各国の学生や協賛者、講演依頼者との連絡を行います。企業の協賛を得ること、また様々な分野にまたがった講演者を招聘することは国際会議HPAIRの誘致成功にとって重要な条件であるため、そのための渉外を担当します。"
        },
        {   
            dept: "③企画運営部",
            description: "各種企画の運営を行います。"
        },
        {   
            dept: "④会計部",
            description: "予算管理を行います。国際会議HPAIRは協賛スポンサーからいただいだ予算の範囲内で各種企画を行います。"
        },
        {   
            dept: "⑤広報部",
            description: "国際共創会Diligentの活動内容を対外的に発信するために、ビラの作成、各種デザイン、SNS運営などを行います。また、誘致チームメンバーの募集を担当します。"
        },
    ]

    return (
        <div className='team'>
            <h1>Our Team</h1>
            <p>私たちは現在、次のようなチームに分かれHPAIRの誘致に向けて活動しています。</p>
            <p>チームメンバーは常時募集しておりますので、国際会議の誘致や運営に興味のある方はApplyページよりご連絡ください。</p>
            <p></p>
            <ul>
            {
                teams.map(team => (
                    <li>
                        <h2>{team.dept}</h2>
                        <p>{team.description}</p>
                    </li>
                ))
            }
            </ul>
        </div>
    )
}