import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { init, send } from 'emailjs-com';
import { Button, colors } from "@mui/material";
import '../css/Application.css';

export const Application = () => {
    const [name, setName] = useState('');
    const [kana, setKana] = useState('');
    const [mail, setMail] = useState('');
    const [belong, setBelong] = useState('');
    const [capability, setCapability] = useState('');
    const [message, setMessage] = useState('');

    const publicKEY = 'V7QZ1KtwIz8z5-xX_';
    const serviceID = 'DILIGENT';
    const templateID = 'template_kyo35hs';

    const [hasSent, setHasSent] = useState('');
    const [isError, setIsError] = useState('');

    const disableSend = name === '' || kana === '' || mail === '' || belong === '' || capability === '' || message === '';
    const isInvalid = name.length > 30 || kana.length > 30 || mail.length > 30 || belong.length > 30 || capability.length > 1000|| message.length > 1000

    const sendMail = () => {
        if (
            publicKEY !== undefined &&
            serviceID !== undefined &&
            templateID !== undefined &&
            !disableSend
        ) {
            init(publicKEY);
        
            const template_param = {
                name: name,
                kana: kana,
                email: mail,
                belong: belong,
                capability: capability,
                message: message,
            };

            emailjs.send(serviceID, templateID, template_param, publicKEY).then(() => {                              
                setIsError(false);
                setHasSent(true);
                setName('');
                setKana('');
                setMail('');
                setBelong('');
                setCapability('');
                setMessage('');
            });
        }          
    }

    const handleClick = (e) => {
        if(disableSend){
            setIsError("必須項目を全て入力してください。");
        }
        if(isInvalid){
            setIsError("入力内容が長すぎます。");
        }
        e.preventDefault();
        sendMail();
    };

    return (
        <div className='application'>
            <h1>Join Us</h1>
            <p>
                国際共創会Diligentは、ともに国際会議の誘致に向けて活動するメンバーを募集しています。<br></br>
                チームへの参加を希望される方は、以下のフォームよりご連絡ください。<br></br>
                <br></br>
                内容を確認次第、記入いただいたEmailアドレスにご連絡を差し上げます。
            </p>
            <form>
                <div className='flex-box'>
                    <div className='name'>
                        <div>
                            <label htmlFor="nameForm">お名前 <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="nameForm" className="formInput" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>

                    <div className='kana'>
                        <div>
                            <label htmlFor="kanaForm">フリガナ <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="kanaForm" className="formInput" value={kana} onChange={(e) => setKana(e.target.value)} required />
                    </div>
                </div>

                <div>
                    <label htmlFor="mailForm">メールアドレス <span style={{color: "red"}}>*</span></label>
                </div>
                <input type="email" id="mailForm" className="formInput" value={mail} onChange={(e) => setMail(e.target.value)} required/>
                
                <div>
                    <label htmlFor="belongForm">所属大学・学部等 <span style={{color: "red"}}>*</span></label>
                </div>
                <input type="belong" id="belongForm" className="formInput" value={belong} onChange={(e) => setBelong(e.target.value)} required/>
                
                <div>
                    <label htmlFor="capailityForm">英語運用能力、その他特筆すべきスキルなど <span style={{color: "red"}}>*</span></label>
                </div>
                <input type="capaility" id="capailityForm" className="formInput" value={capability} onChange={(e) => setCapability(e.target.value)} required/>
                
                <div>
                    <label htmlFor="mailContentForm">その他、ご質問等があればご記入ください。</label>
                </div>
                <textarea type="text" id="mailContentForm" className="formInput formInputMessage" value={message} onChange={(e) => setMessage(e.target.value)} />
                
                {
                    hasSent ? (
                        <p className='success'><b>お問い合わせが送信されました。<br />入力いただいたメールアドレスに確認メールが届いたことをご確認ください。</b></p>
                    ) : undefined
                }
                {
                    isError ? (
                        <p className='error'><span style={{color: "red"}}><b>{isError}</b></span></p>
                    ) : undefined
                }
                <div className="btns">
                    <div>
                        {hasSent ? (
                            <button className="hasSent">
                                送信完了✓
                            </button>
                        ) : (
                            <Button variant="contained" onClick={handleClick} className='unSent'>
                                送信
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
}