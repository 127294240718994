import React, { useState } from 'react';
import {NavLink, Link} from 'react-router-dom';
import "./Navbar.css";
import icon_dili from '../img/dili-logo.png';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; 

export const Navbar = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;
    const [isClosed, setClosed] = useState(true)

    const showSideBar = () => {
        isClosed ? setClosed(false) : setClosed(true)
    }

    const pages = [
        {
            "dir": "/",
            "name": "Top"
        },
        {
            "dir": "about",
            "name": "What's HPAIR?"
        },
        {
            "dir": "/team",
            "name": "Our Team"
        },
        // {
        //     "dir": "/philosophy",
        //     "name": "Philosophy"
        // },
        {
            "dir": "/application",
            "name": "Apply"
        },
        {
            "dir": "/contact",
            "name": "Contact"
        },
        {
            "dir": "/faq",
            "name": "FAQ"
        }
    ]

    return(
        <div>
            {
                !isMobile ? (
                    <nav>
                        <div className="nav-left">
                        <Link to="/"><img className="icon-dili" src={icon_dili} alt="" /></Link>
                        <Link to="/" className='title'>国際共創会Diligent</Link>
                        </div>
                        <ul>
                            {
                                pages.map(e => (
                                    <li><NavLink to={e.dir}>{e.name}</NavLink></li>
                                ))
                            }
                        </ul>
                    </nav>
                ) : (
                    <nav>
                        <div className="nav-left">
                            <Link onClick={() => setClosed(true)} to="/"><img className="icon-dili" src={icon_dili} alt="" /></Link>
                            <Link to="/" className='title' style={{color: 'white', fontSize: '20px', fontWeight: 'normal'}}>国際共創会Diligent</Link>
                        </div>
                        <IconButton className='menu-button' onClick={() => showSideBar()}>
                            {
                                isClosed ? (
                                    <MenuIcon style={{color: 'white'}}/>
                                ) : (
                                    <CloseIcon style={{color: 'white'}}/>
                                )
                            }
                        </IconButton>
                        <div className='side-bar' style={isClosed ? {width: '0vw', overflow: 'hidden'} : {width: '100vw'}}>
                            <ul>
                                {
                                    pages.map(e => (
                                        <li style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}><NavLink to={`${e.dir}`} onClick={() => showSideBar()}>{e.name}</NavLink></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </nav>
                )
            }    
        </div>
    )
}