import './App.css';
import { useEffect, useState } from 'react';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { About ,Home ,Philosophy ,Team, Contact, Application, Faq } from './components/pages';
import { Routes, Route, useLocation } from 'react-router-dom';

function ScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const handleResize = () => {
    window.location.reload();
  };
  
  useEffect(() => {
    window.addEventListener('orientationchange', handleResize);

    return () => {
        window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  // const [cursorX, setCoursorX] = useState(false);
  // const [cursorY, setCoursorY] = useState(false);
  // window.addEventListener('mousemove', e => {
  //     setCoursorX(e.screenX);
  //     setCoursorY(e.screenY - 180)
  // })

  return (
    <div className="App">
      <Navbar />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/philosophy" element={<Philosophy/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/application" element={<Application/>} />
        <Route path="/faq" element={<Faq/>} />
      </Routes>
      <Footer />
      {/* <div className='cursor' style={{left: `${cursorX}px`, top: `${cursorY}px`}}></div> */}
    </div>
  );
}

export default App;
