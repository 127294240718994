import React from 'react';
import '../css/Faq.css';

export const Faq = () => {

    return (
        <div className='faq'>
            <h1>FAQ</h1>

        </div>
    )
}